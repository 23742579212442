import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { findLast, omegaEvent, uuid, validEmoji } from '../browser/utils'
import { add_messages, Message, Role, RootContext } from './Root'
import Answer from './Answer'
import { OmegaEventId } from '../browser/omega'
import { intl } from '../browser/locales'
const SendIcon =
  'https://img-ys011.didistatic.com/static/cooper_cn/livechat/assets/livechat_send.svg'

function Textarea() {
  const {
    store: {
      isLoading,
      messages,
    },
    dispatch,
  } = useContext(RootContext)
  const [value, setValue] = useState('')
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const result = useMemo(() => {
    return value.trim()
  }, [value])

  // 是否reset上下文
  const detectIsNeedResetContext = () => {
    if (messages.length > 0) {
      const lastMessage = findLast<Message>(messages, (item) => item.group === 'qa')
      if (!lastMessage) return true
      return Date.now() - lastMessage.createTime > 1000 * 60 * 60 * 24
    }

    return true
  }

  const handleSend = () => {
    if (isDisableSend) return

    omegaEvent(OmegaEventId.send, '', {})

    const isNeedResetContext = detectIsNeedResetContext()
    const messages = [
      {
        id: uuid(),
        role: Role.user,
        body: result,
        createTime: Date.now(),
        group: 'qa',
      },
      {
        id: uuid(),
        role: Role.assistant,
        body: (
          <Answer
            isNeedResetContext={isNeedResetContext}
            question={result}
          />
        ),
        createTime: Date.now(),
        group: 'qa',
      },
    ]

    dispatch({
      type: add_messages,
      value: messages,
    })

    setValue('')
  }

  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // omegaEvent(OmegaEventId.input, '', {})
    const ele = event.target
    setValue(ele.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { nativeEvent } = event
    if (nativeEvent.key === 'Enter' && !nativeEvent.isComposing) {
      event.preventDefault()
      handleSend()
    }
  }

  const errorTip = useMemo(() => {
    if (!result) {
      return ' '
    }

    if (validEmoji(result)) {
      return '问答内容不支持emoji'
    }
    return ''
  }, [value])

  const isDisableSend = useMemo(() => {
    return Boolean(errorTip) || isLoading
  }, [errorTip, isLoading])

  useEffect(() => {
    const ele = textareaRef.current!
    ele.style.height = '22px'
    ele.style.height = Math.min(ele.scrollHeight, 88) + 'px'
    ele.focus();
  }, [value])

  return (
    <div className={'icschat-textarea-container'}>
      <div className={'icschat-textarea-box'}>
        <div className={'icschat-textarea-box-wrapper'}>
          <textarea
            ref={textareaRef}
            autoFocus
            onKeyDown={handleKeyDown}
            value={value}
            onChange={handleInput}
            className={'icschat-textarea'}
            placeholder={intl('请输入你想了解的问题')}
          ></textarea>
          <div className={'icschat-textarea-box-footer'}>
            <div className={'icschat-textarea-error'}>{errorTip}</div>
            <button
              className={'icschat-textarea-send-button'}
              disabled={isDisableSend}
              onClick={handleSend}
            >
              <img className={'icschat-textarea-send-button-icon'} src={SendIcon} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Textarea
